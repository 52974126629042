<template>
<div class="login">
    <div class="fullview" v-if="loading">
        <div class="loading-spacer"></div>
        <vue-loading v-if="loading" type="spin" color="#333" :size="{ width: '300px', height: '300px' }"></vue-loading>
    </div>
	<b-form @submit.prevent="login" ref="form">
        <img src="@/assets/field_ex_logo.png" />
        <b-form-group label="アカウント（メールアドレス）">
            <b-form-input 
                v-model="username" 
                type="email" 
                size="lg"
                :state="email_state"
            >
            </b-form-input>
            
        </b-form-group>
        <b-form-group label="パスワード">
            <b-form-input 
                v-model="password" 
                type="password" 
                size="lg"
                :state="password_state"
            ></b-form-input>
        </b-form-group>
        <b-button type="submit" size="lg" >ログイン</b-button>
    </b-form>
</div>
</template>


<script>
import { VueLoading } from 'vue-loading-template'

export default {
	components: {
		VueLoading
	},
	computed: {
        email_state() {
            if (this.username){
                return this.username.indexOf('@') > -1 ? true: false
            } else {
                return null
            }
        }
        ,password_state() {
            if (this.password){
                return this.password.length > 4 ? true: false
            } else {
                return null
            }
        }       
	},
    created() {
        // ローカルストレージをすべてリセット
        window.localStorage.clear();
    },
	data() {
		return {
            username: null
            ,password: null
            ,loading: false
		};
	},
    methods: {
        login: function(e) {
            this.loading = true;
            // console.log(this.username , this.password )
            if (this.$refs.form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
            } else {
                this.showMap()
            }
        },
        showMap: async function() {
            await this.$store.dispatch("login", {"user": this.username, "password": this.password});
            this.loading = false;
			this.$router
				.push({ name: "Home", params: {} })
				.catch(() => {});
		},
    }
};
</script>
<style scoped lang="scss">
.login {
    min-height: 100vh; display:flex; justify-content: center; align-items: center;
    form{
        width: 300px; margin: 0 auto; 
        img { width: 100%; margin-bottom: 1em; }
        input { margin-bottom: 1em; }
        button { width: 100%; }
    }
}
.fullview {
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.loading-spacer {
  height: 30%;
}
</style>